import { useCallback, useEffect, useMemo, useState } from 'react'
import { getVisitInfo } from '@ucheba/utils/hooks/useVisitsTracker'
import { SCHOOL_SITE_NAME } from '@ucheba/utils/constants/core'
import {
  clientProductsRequestsSelectors,
  clientProductsRequestsThunks,
} from '@ucheba/store/client-products/requests'
import { useDispatch, useSelector, useStore } from 'react-redux'
import {
  authPhoneCodeActions,
  authPhoneCodeSelectors,
  authPhoneCodeThunks,
} from '@ucheba/store/auth/phone/code'
import { useTimer } from '@ucheba/utils/hooks/useTimer'
import { ELoading } from '@ucheba/store/utils/response/types'
import { analyticEvent } from '@ucheba/utils/helpers/analytics/events'
import {
  EAnalyticEvents,
  EAnalyticEventTargets,
  EAnalyticSections,
} from '@ucheba/utils/helpers/analytics/events/types'
import {
  useEdPartnersClickId,
  useLovkoProClickId,
  useReferralCode,
  useSpecialOffer,
} from '../AppliactionForm/bll'
import {
  EApplicationFormProductTypes,
  EApplicationFunnels,
} from '../AppliactionForm/types'
import { codeLength } from '../AppliactionForm/constants'
import { ETypeRequest } from './types'

interface IUseReqForm {
  (
    funnel: EApplicationFunnels,
    productType: EApplicationFormProductTypes,
    requestTarget: ETypeRequest,
    onSubmitForm?: () => void
  ): {
    initialValues: {
      name: string
      phone: string
      isParent: string
    }
    onSubmit: (values: any) => void
    onPhoneConfirmSubmit: (code: string) => void
    needConfirmPhone: boolean
    isRequestSendCompleted: boolean
    onPhoneConfirmClose: () => void
    needShowMessengers: boolean
    onMessengersDialogClose: () => void
    phone: string | null
    isLoading: boolean
  }
}

export const useRequestForm: IUseReqForm = (
  funnel,
  productType,
  requestTarget,
  onSubmitForm
) => {
  const dispatch = useDispatch()
  const [needConfirmPhone, setNeedConfirmPhone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [needShowMessengers, setNeedShowMessengers] = useState(false)
  const [currentFormValues, setCurrentFormValues] = useState<{
    name: string
    phone: string
    isParent: string
  } | null>(null)
  const store = useStore()

  const initialValues = useMemo(() => {
    return {
      name: '',
      phone: '',
      isParent: 'false',
    }
  }, [])

  const lovkoProClickId = useLovkoProClickId()
  const edPartnersClickId = useEdPartnersClickId()
  const specialOfferCode = useSpecialOffer()
  const referralCode = useReferralCode('egeReferralCode')

  const sendRequest = useCallback(
    async (values: any, code: string | null): Promise<any> => {
      setIsLoading(true)
      const data = {
        funnel,
        productType,
        requestTarget,
        referralCode,
        specialOfferCode,
        code,
        phone: values.phone,
        name: values.name,
        visit: getVisitInfo(SCHOOL_SITE_NAME),
        isParent: values.isParent,
        lovkoProClickId,
        edPartnersClickId,
      }
      const result = await dispatch(clientProductsRequestsThunks.send({ data }))
      setIsLoading(false)
      return result
    },
    [
      currentFormValues,
      funnel,
      productType,
      requestTarget,
      referralCode,
      specialOfferCode,
      setIsLoading,
      dispatch,
      lovkoProClickId,
      edPartnersClickId,
    ]
  )

  const onSubmit = useCallback(
    async (values) => {
      setCurrentFormValues(values)
      const clientProductsRequestsEntity = await sendRequest(values, null)

      const error = clientProductsRequestsSelectors.error(store.getState())

      if (!error) {
        if (onSubmitForm) {
          onSubmitForm()
        }

        if (requestTarget === ETypeRequest.oge_lead_request) {
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.ogeLeadRequest,
            section: EAnalyticSections.requestForm,
          })
        } else {
          analyticEvent({
            targets: [
              EAnalyticEventTargets.ym,
              EAnalyticEventTargets.ga,
              EAnalyticEventTargets.ur,
              EAnalyticEventTargets.gtm,
            ],
            eventName: EAnalyticEvents.egeLeadRequest,
            section: EAnalyticSections.requestForm,
          })
        }

        if (!clientProductsRequestsEntity?.phoneConfirmed) {
          // Если нужно подтвердить телефон
          setNeedConfirmPhone(true)
        } else {
          // Если телефон подтвержден
          setNeedShowMessengers(true)
        }
      }
    },
    [sendRequest, store, setCurrentFormValues, onSubmitForm]
  )

  const onMessengersDialogClose = useCallback(() => {
    setNeedShowMessengers(false)
  }, [setNeedShowMessengers])

  const onPhoneConfirmSubmit = useCallback(
    async (code: string) => {
      await sendRequest(currentFormValues, code)
      const error = clientProductsRequestsSelectors.error(store.getState())
      if (!error) {
        setNeedShowMessengers(true)
        setNeedConfirmPhone(false)
      }
    },
    [currentFormValues, sendRequest, setNeedShowMessengers, setNeedConfirmPhone, store]
  )

  const onPhoneConfirmClose = useCallback(() => {
    setNeedConfirmPhone(false)
  }, [setNeedConfirmPhone])

  return {
    initialValues,
    onSubmit,
    onPhoneConfirmSubmit,
    onPhoneConfirmClose,
    needConfirmPhone,
    needShowMessengers,
    onMessengersDialogClose,
    phone: currentFormValues?.phone,
    isLoading,
  }
}

export const usePhoneConfirmDialog = (
  phone: string,
  onSubmit: (code: string) => void
): any => {
  const dispatch = useDispatch()
  const timout = useSelector(authPhoneCodeSelectors.timeout)
  const sendCodeLoading = useSelector(authPhoneCodeSelectors.loading)
  const [error, setError] = useState<string | null>(null)
  /* Получаем таймер */
  const { timeLeft, isTimeLeft } = useTimer({
    seconds: timout,
    secondsByMinutes: true,
  })

  const onInputCode = useCallback(
    (_, codeValue) => {
      if (codeValue.length === codeLength) {
        onSubmit(codeValue)
      }
      setError(null)
    },
    [onSubmit]
  )
  /* Когда заканчивается время, ресетим его в сторе */
  useEffect(() => {
    if (isTimeLeft) {
      dispatch(authPhoneCodeActions.setTimeoutValue(0))
    }
  }, [dispatch, isTimeLeft])

  /* Метод запроса нового кода */
  const getNewCode = useCallback(() => {
    dispatch(authPhoneCodeThunks.sendCode({ data: { phone } }))
  }, [dispatch, phone])

  /* Запрашиваем код при инициализации */
  useEffect(() => {
    if (phone && sendCodeLoading === ELoading.idle) {
      getNewCode()
    }
  }, [getNewCode, phone, sendCodeLoading])

  return {
    timeLeft,
    isTimeLeft,
    onInputCode,
    getNewCode,
    error,
  }
}
